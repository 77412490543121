import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"570px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Êtes-vous sûr de vouloir supprimer ce candidat ?")]),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"color":"blue","rounded":"","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Annuler")]),_c(VBtn,{attrs:{"color":"red","rounded":"","text":""},on:{"click":function($event){return _vm.deleteCandidat()}}},[_vm._v("Supprimer")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }