<template>
  <v-dialog v-model="dialog" max-width="570px">
    <v-card :loading="loading">
      <v-card-title class="headline">Êtes-vous sûr de vouloir supprimer ce candidat ?</v-card-title>
      <v-card-actions class="justify-center">
        <v-btn color="blue" rounded text @click="closeDialog()">Annuler</v-btn>
        <v-btn color="red" rounded text @click="deleteCandidat()">Supprimer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { deleteCandidats } from '../../api/candidats';

export default {
  data: () => ({
    dialog: false,
    idCandidat: null,
    typeCandidat: null,
    loading: false
  }),
  methods: {
    openDialog(idCandidat, typeCandidat) {
      // Ouverture du dialogue de suppression et récupération de l'id et du type du candidat à supprimer
      this.dialog = true
      this.idCandidat = idCandidat;
      this.typeCandidat = typeCandidat
    },
    async deleteCandidat() {
      this.loading = true
      // Suppression du candidat via l'API
      await deleteCandidats(this.idCandidat, this.typeCandidat);
      this.loading = false
      // Fermture du dialogue de suppression
      this.dialog = false
      // Actualisation du tableau de candidats
      this.$emit('candidatDeleted');
    },
    //Fermeture du dialogue de suppression
    closeDialog() {
      this.dialog = false
    },
  },
};
</script>