import axiosClient from "./customAxios";

export const exportToGSheet = async (email, filters) => {

	for (let key in filters) {
		if (Array.isArray(filters[key]) && filters[key].length > 0) {
		  filters[key] = filters[key].join(",");
		}
	}

	const config = {
		params: {...filters, email: email}
	}

	try {
		//console.log({config})
		const response = await axiosClient.get(process.env.VUE_APP_ROOT_API + `export`, config);
		return response.data;
	} catch (error) {
			throw error;
	}
}
