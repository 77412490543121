<template>
  <v-dialog v-model="dialog" max-width="720px">
    <v-card color="white">
      <v-card-title class="d-flex justify-center py-3 blue lighten-1 mc2i white--text">
        <span class="headline"> {{ candidat.prenom }} {{ candidat.nom }} </span>
      </v-card-title>
      <v-tabs v-model="tab" fixed-tabs>
        <v-tab>Détails</v-tab>
      </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card color="grey lighten-4 rounded-xl ma-2 pa-4" flat>

          <v-progress-linear
            :value="statusToPercentage(candidat).value"
            :color="statusToPercentage(candidat).color"
            height="16"
            class="rounded-pill"
          >
            <span>{{ statusToPercentage(candidat).text }}</span>
          </v-progress-linear>

          <v-list color="grey lighten-4 d-flex flex-column" dense>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-chip>TAC : {{ candidat.trigrammeTAC }}</v-chip>
            </v-list-item>
            <v-list-item >
              <v-list-item-icon>
                <v-icon>mdi-calendar-range</v-icon>
              </v-list-item-icon>
              <v-chip>Date de disponibilité : {{ dateInTextComponent(candidat.disponibilite) }}</v-chip>
            </v-list-item>
            <v-list-item >
              <v-list-item-icon>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-icon>
              <v-chip>Ecole : {{ candidat.nom_ecole }}</v-chip>
            </v-list-item>
            <v-list-item >
              <v-list-item-icon>
                <v-icon>mdi-school</v-icon>
              </v-list-item-icon>
              <v-chip>Type d'école : {{ candidat.type_ecole }}</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.rang_ecole != null">
              <v-list-item-icon>
                <v-icon>mdi-book</v-icon>
              </v-list-item-icon>
              <v-chip>Rang ecole : {{ candidat.rang_ecole }}</v-chip>
            </v-list-item>
            <v-list-item v-if="type_candidat(candidat) === 'ope' && candidat.annee_diplomation != null">
              <v-list-item-icon>
                <v-icon>mdi-school</v-icon>
              </v-list-item-icon>
              <v-chip>Année de diplomation : {{ candidat.annee_diplomation }}</v-chip>
            </v-list-item>
            <v-list-item >
              <v-list-item-icon>
                <v-icon>mdi-check-circle</v-icon>
              </v-list-item-icon>
              <v-chip>Statut : {{ candidat.statut }}</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.desistement != null">
              <v-list-item-icon>
                <v-icon>mdi-exit-run</v-icon>
              </v-list-item-icon>
              <v-chip>Desistement : {{ candidat.desistement }}</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.motif_desistement != null">
              <v-list-item-icon>
                <v-icon>mdi-alert</v-icon>
              </v-list-item-icon>
              <v-chip>Motif desistement : {{ candidat.motif_desistement }}</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.matricule != null">
              <v-list-item-icon>
                <v-icon>mdi-badge-account-horizontal</v-icon>
              </v-list-item-icon>
              <v-chip>Matricule : {{ candidat.matricule }}</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.profil != null">
              <v-list-item-icon>
                <v-icon>mdi-account-tie</v-icon>
              </v-list-item-icon>
              <v-chip>Profil : {{ candidat.profil }}</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.profilFCT != null">
              <v-list-item-icon>
                <v-icon>mdi-account-tie</v-icon>
              </v-list-item-icon>
              <v-chip>Profil : {{ candidat.profilFCT }}</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.sexe != null">
              <v-list-item-icon>
                <v-icon>{{ candidat.sexe === 'Homme' ? 'mdi-gender-male' : 'mdi-gender-female' }}</v-icon>
              </v-list-item-icon>
              <v-chip>Sexe : {{ candidat.sexe }}</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.experience != null">
              <v-list-item-icon>
                <v-icon>mdi-wrench</v-icon>
              </v-list-item-icon>
              <v-chip>Experience : {{ candidat.experience }}</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.origine != null">
              <v-list-item-icon>
                <v-icon>mdi-arrow-right-bold</v-icon>
              </v-list-item-icon>
              <v-chip>Origine : {{ candidat.origine }}</v-chip>
            </v-list-item>
            <v-list-item >
              <v-list-item-icon>
                <v-icon>mdi-phone</v-icon>
              </v-list-item-icon>
              <v-chip>Prequa. tel : {{ dateEnToFrComponent(candidat.prequa_tel) }}</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.expertise != null">
              <v-list-item-icon>
                <v-icon>mdi-folder-star</v-icon>
              </v-list-item-icon>
              <v-chip>Expertise : {{ candidat.expertise }}</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.souhait != null">
              <v-list-item-icon>
                <v-icon>mdi-oil-lamp</v-icon>
              </v-list-item-icon>
              <v-chip>Souhait : {{ candidat.souhait }}</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.profilFCT != null">
              <v-list-item-icon>
                <v-icon>mdi-office-building</v-icon>
              </v-list-item-icon>
              <v-chip>Entité : {{ candidat.label_entite }}</v-chip>
            </v-list-item>
            <v-list-item >
              <v-list-item-icon>
                <v-icon>mdi-office-building</v-icon>
              </v-list-item-icon>
              <v-chip>Pôle : {{ candidat.pole }}</v-chip>
            </v-list-item>
            <v-list-item v-if=" type_candidat(candidat) === 'ope' && candidat.pole_affectation != null">
              <v-list-item-icon>
                <v-icon>mdi-office-building</v-icon>
              </v-list-item-icon>
              <v-chip>Pôle d'affectation: {{ candidat.pole_affectation }}</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.source_chasse != null" >
              <v-list-item-icon>
                <v-icon>mdi-magnify</v-icon>
              </v-list-item-icon>
              <v-chip>Chasse : {{ candidat.source_chasse }}</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.profilFCT != null">
              <v-list-item-icon>
                <v-icon>mdi-file-document-outline</v-icon>
              </v-list-item-icon>
              <v-chip>Type de contrat : {{ candidat.label_contrat }}</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.expertises_cles != null">
              <v-list-item-icon>
                <v-icon>mdi-folder-star</v-icon>
              </v-list-item-icon>
              <v-chip >Expertises Cles : {{ candidat.expertises_cles.replace(/_/g, ' , ') }}</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.label_ville_province != null">
              <v-list-item-icon>
                <v-icon>mdi-map-marker</v-icon>
              </v-list-item-icon>
              <v-chip >Province : {{ candidat.label_ville_province }}</v-chip>
            </v-list-item>
            <v-list-item v-if="type_candidat(candidat) === 'ope' && (candidat.transverse !== 0 && candidat.transverse !== null)">
              <v-list-item-icon>
                <v-icon>mdi-transit-transfer</v-icon>
              </v-list-item-icon>
              <v-chip >Transverse</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.cooptation !== 0 && candidat.cooptation !== null">
              <v-list-item-icon>
                <v-icon>mdi-account-group </v-icon>
              </v-list-item-icon>
              <v-chip >Cooptation</v-chip>
            </v-list-item>
            <v-list-item v-if="candidat.is_linkedin !== 0 && candidat.is_linkedin !== null">
              <v-list-item-icon>
                <v-icon>mdi-linkedin</v-icon>
              </v-list-item-icon>
              <v-chip >Remontée Linkedin</v-chip>
            </v-list-item>
            <v-list-item v-if="type_candidat(candidat) === 'ope' && (candidat.is_data !== 0 && candidat.is_data !== null)">
              <v-list-item-icon>
                <v-icon>mdi-database</v-icon>
              </v-list-item-icon>
              <v-chip >Data</v-chip>
            </v-list-item>
            <v-list-item v-if="type_candidat(candidat) === 'ope' && (candidat.mc2i_opportunities !== 0 && candidat.mc2i_opportunities !== null)">
              <v-list-item-icon>
                <v-icon>mdi-lightbulb-on</v-icon>
              </v-list-item-icon>
              <v-chip>mc2i Opp</v-chip>
            </v-list-item>
            <v-list-item v-if="(candidat.high_potential !== 0 && candidat.high_potential !== null)">
              <v-list-item-icon>
                <v-icon>mdi-lightning-bolt</v-icon>
              </v-list-item-icon>
              <v-chip >HP</v-chip>
            </v-list-item>
            <v-list-item v-if="type_candidat(candidat) === 'ope'&& (candidat.is_reconversion_femme_num !== 0 && candidat.is_reconversion_femme_num !== null)">
              <v-list-item-icon>
                <v-icon>mdi-refresh</v-icon>
              </v-list-item-icon>
              <v-chip >Reconvertion femme du numerique</v-chip>
            </v-list-item>
            <v-list-item v-if="type_candidat(candidat) === 'ope' && (candidat.statut === 'Embauché' && candidat.nom_fonction && candidat.nom_fonction !== null)">
              <v-list-item-icon>
                <v-icon>mdi-briefcase-outline</v-icon>
              </v-list-item-icon>
              <v-chip >Fonction : {{ candidat.nom_fonction }}</v-chip>
            </v-list-item>
          </v-list>
        </v-card>
      </v-tab-item>

      <!-- <v-tab-item>
        <v-card color="grey lighten-4 rounded-xl ma-2 pa-4" flat>

          <span v-if="candidat.rdv1 == null">Le candidat n'a pas de RDV.</span>

          <v-card class="grey lighten-4 mb-4" flat v-if="candidat.rdv1 != null" height="80">
            <span>RDV 1</span><br>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  :value="dateEnToFrComponent(candidat.rdv1)"
                  readonly
                  prepend-icon="mdi-calendar"
                  outlined
                  dense
                  rounded
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field 
                  v-model="candidat.trigramme1"
                  readonly
                  prepend-icon="mdi-account"
                  outlined
                  dense
                  rounded
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="5" v-if="candidat.commentaire_rdv1 != null">
                <v-textarea
                  prepend-icon="mdi-comment"
                  :value="candidat.commentaire_rdv1"
                  readonly
                  rows="2"
                  no-resize
                  outlined
                  dense
                  rounded
                  background-color="grey lighten-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>
          
          
          <v-card class="grey lighten-4 mb-4" flat v-if="candidat.rdv2 != null" height="80">
            <span>RDV 2</span><br>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  :value="dateEnToFrComponent(candidat.rdv2)"
                  readonly
                  prepend-icon="mdi-calendar"
                  outlined
                  dense
                  rounded
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field 
                  v-model="candidat.trigramme2"
                  readonly
                  prepend-icon="mdi-account"
                  outlined
                  dense
                  rounded
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="5" v-if="candidat.commentaire_rdv2 != null">
                <v-textarea
                  prepend-icon="mdi-comment"
                  :value="candidat.commentaire_rdv2"
                  readonly
                  rows="2"
                  no-resize
                  outlined
                  dense
                  rounded
                  background-color="grey lighten-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="grey lighten-4 mb-4" flat v-if="candidat.rdv3 != null" height="80">
            <span>RDV 3</span><br>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  :v-model="dateEnToFrComponent(candidat.rdv3)"
                  readonly
                  prepend-icon="mdi-calendar"
                  outlined
                  dense
                  rounded
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field 
                  v-model="candidat.trigramme3"
                  readonly
                  prepend-icon="mdi-account"
                  outlined
                  dense
                  rounded
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="5" v-if="candidat.commentaire_rdv3 != null">
                <v-textarea
                  prepend-icon="mdi-comment"
                  :value="candidat.commentaire_rdv3"
                  readonly
                  rows="2"
                  no-resize
                  outlined
                  dense
                  rounded
                  background-color="grey lighten-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="grey lighten-4 mb-4" flat v-if="candidat.rdv4 != null" height="80">
            <span>RDV 4</span><br>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="candidat.rdv4"
                  readonly
                  prepend-icon="mdi-calendar"
                  outlined
                  dense
                  rounded
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field 
                  v-model="candidat.trigramme4"
                  readonly
                  prepend-icon="mdi-account"
                  outlined
                  dense
                  rounded
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="5" v-if="candidat.commentaire_rdv4 != null">
                <v-textarea
                  prepend-icon="mdi-comment"
                  :value="candidat.commentaire_rdv4"
                  readonly
                  rows="2"
                  no-resize
                  outlined
                  dense
                  rounded
                  background-color="grey lighten-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="grey lighten-4 mb-4" flat v-if="candidat.offre != null" height="64">
            <span>Offre</span><br>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="candidat.offre"
                  readonly
                  prepend-icon="mdi-calendar"
                  outlined
                  dense
                  rounded
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field 
                  v-model="candidat.resp_offre"
                  readonly
                  prepend-icon="mdi-account"
                  outlined
                  dense
                  rounded
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field 
                  :value="dateEnToFrComponent(candidat.date_demarrage)"
                  readonly
                  prepend-icon="mdi-calendar-start"
                  outlined
                  dense
                  rounded
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
      
        </v-card>
      </v-tab-item>
    -->
    </v-tabs-items> 
    </v-card>
  </v-dialog>
</template>


<script>
import { dateEnToFr, dateInText } from '../commonFunction/date';

export default {
  data: () => ({
    dialog: false,
    dialogRDV: false,
    tab: null,
    candidat: {},
    items: [
      { text: 'RDV 1', value: 'rdv1'},
      { text: 'RDV 2', value: 'rdv2' },
      { text: 'RDV 3', value: 'rdv3' },
      { text: 'RDV 4', value: 'rdv4' },
    ],
  }),
  methods: {
    showInfos(candidat) {
      this.candidat = candidat;
      this.dialog = true;
      //console.log(candidat);

      // Call the type_candidat method and log the result
      const candidatType = this.type_candidat(candidat);
      //console.log('Type de candidat:', candidatType);
    },
    type_candidat(candidat) {
      if (this.candidat.profil) {
        return 'ope';
      } else if (this.candidat.profilFCT) {
        return 'fct';
      } else {
        // Default value or handle other cases if needed
        return 'unknown';
      }
    },
    dateEnToFrComponent(dateString) {
      return dateEnToFr(dateString);
    },
    dateInTextComponent(dateString) {
      return dateInText(dateString);
    },
    statusToPercentage(candidat) {
      if (candidat.statut == "KO" || candidat.statut == "Désistement")
        return {text: "KO", value: 100, color: "rgb(255, 0, 0)"};
      else if (candidat.statut == "Embauché") 
        return {text: "Embauché", value: 100, color: "rgb(0, 128, 0)"};
      else if (candidat.offre != undefined && candidat.offre != "")
        return {text: "Offre", value: 84, color: "rgb(0, 255, 255)"};
      else if (candidat.rdv3 != undefined && candidat.rdv3 != "")
        return {text: "RDV 3", value: 67, color: "rgb(255, 166, 0)"};
      else if (candidat.rdv2 != undefined && candidat.rdv2 != "")
        return {text: "RDV 2", value: 50, color: "rgb(255, 255, 0)"};
      else if (candidat.rdv1 != undefined && candidat.rdv1 != "")
        return {text: "RDV 1", value: 17, color: "rgb(255, 255, 255)"};
      else
        return {text: "Sans RDV", value: 0, color: "rgb(255, 255, 255)"};
  }
}
}

</script>