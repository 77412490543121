<template>
  <div>
    <v-dialog v-model="dialog" max-width="640px">
      <v-card :loading="loading">
        <v-card-title class="d-flex justify-center py-6 mb-6 blue lighten-1 mc2i white--text">
          Modifier le candidat
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                
                <!--Statut-->
                <v-col cols="12" sm="12" md="12">
                  <v-select v-model="candidat.statut" :items="champs.statuts" label="Statut*"
                    :rules="[(v) => !!v || 'Le statut est obligatoire']" outlined dense rounded
                    background-color="grey lighten-4" required></v-select>
                </v-col>
                <!--Trigramme-->
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete v-model="candidat.trigrammeTAC" :items="champs.TAC" label="TAC*"
                    :rules="[(v) => !!v || 'Le TAC est obligatoire']" outlined dense rounded
                    background-color="grey lighten-4" required></v-autocomplete>
                </v-col>
                <!-- Prequa Tel -->
                <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'OK'">
                  <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                    min-width="auto" rounded="xl" v-model="isPrequaTelOpen" ref="prequaMenu">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :value="dateEnToFr(candidat.prequa_tel)" label="Préqua. Tel*"
                        prepend-icon="mdi-calendar" readonly clearable v-bind="attrs" v-on="on" :rules="[
                          (v) => !!v || 'La Préqua. tel est obligatoire',
                        ]" outlined dense rounded background-color="grey lighten-4" required></v-text-field>
                    </template>
                    <v-date-picker v-model="candidat.prequa_tel" :first-day-of-week="1" locale="fr-FR"
                      @input="closePrequaTelPicker"></v-date-picker>
                  </v-menu>
                </v-col>



                <!--Nom-->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field background-color="grey lighten-4" dense rounded outlined v-model="candidat.nom" label="Nom*"
                    :rules="[(v) => !!v || 'Le nom est obligatoire']" required></v-text-field>
                </v-col>
                <!--Prénom-->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field background-color="grey lighten-4" dense rounded outlined v-model="candidat.prenom"
                    label="Prenom*" :rules="[(v) => !!v || 'Le prénom est obligatoire']" required></v-text-field>
                </v-col>
                <!-- Disponibilite -->
                <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'OK' || candidat.statut == 'Embauché'">
                  <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                    min-width="auto" rounded="xl" v-model="isDatePickerOpen">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :value="candidat.disponibilite" label="Disponibilité*" prepend-icon="mdi-calendar"
                        readonly clearable v-bind="attrs" v-on="on" :rules="[
                          (v) => !!v || 'La disponibilité est obligatoire',
                        ]" outlined dense rounded background-color="grey lighten-4" required></v-text-field>
                    </template>
                    <v-date-picker v-model="candidat.disponibilite" type="month" no-title scrollable locale="fr-FR"
                      @input="closeDatePicker"></v-date-picker>
                  </v-menu>
                </v-col>

                <!--Profil-->
                <v-col cols="12" sm="12" md="12" v-if="type_candidat == 'ope'">
                  <v-autocomplete v-model="candidat.profil" :items="champs.profils" label="Emploi*"
                    :rules="[(v) => !!v || 'Emploi est obligatoire']" outlined dense rounded
                    background-color="grey lighten-4" required></v-autocomplete>
                </v-col>
                <!--Profil FCT -->
                <v-col cols="12" sm="12" md="12" v-if="type_candidat == 'fct'">
                  <v-autocomplete v-model="candidat.profilFCT" :items="champs.profilsFCT" label="Emploi*"
                    :rules="[(v) => !!v || 'Emploi est obligatoire']" outlined dense rounded
                    background-color="grey lighten-4" required></v-autocomplete>
                </v-col>
                <!--Cooptation-->
                <v-col>
                  <v-checkbox cols="12" v-model="candidat.cooptation" label="Cooptation"></v-checkbox>
                </v-col>
                <!--Remontée Linkedin-->
                <v-col>
                  <v-checkbox cols="12" v-model="candidat.is_linkedin" label="Remontée Linkedin"></v-checkbox>
                </v-col>
                <!-- HP -->
                <v-col >
                  <v-checkbox cols="12" v-model="candidat.high_potential" label="HP"></v-checkbox>
                </v-col>
                <!--Transverse-->
                <v-col>
                  <v-checkbox cols="12" v-if="type_candidat == 'ope'" v-model="candidat.transverse" label="Transverse"></v-checkbox>
                </v-col>
                <!--isData-->
                <v-col>
                  <v-checkbox cols="12" v-if="type_candidat == 'ope'" v-model="candidat.is_data" label="Data"></v-checkbox>
                </v-col>
                <!--mc2i Opp-->
                <v-col>
                  <v-checkbox cols="12" v-if="type_candidat == 'ope'" v-model="candidat.mc2i_opportunities" label="mc2i opp"></v-checkbox>
                </v-col>

                <v-col
                  v-if="candidat.statut === 'Embauché' && candidat.sexe && candidat.sexe.trim().toLowerCase() === 'femme' && type_candidat != 'fct'">
                  <v-checkbox cols="12" v-model="candidat.is_reconversion_femme_num"
                    label="Reconversion femme du numérique"></v-checkbox>
                </v-col>
                <!--Provaince-->
                <v-col cols="12" sm="12" md="12" v-if="type_candidat != 'fct'">
                  <v-select v-model="candidat.label_ville_province" :items="champs.label_ville_province" clearable
                    label="Province"></v-select>
                </v-col>

                <!--Sexe -->
                <v-col cols="12" sm="12" md="12">
                  <v-select v-model="candidat.sexe" :items="champs.sexe" label="Sexe*"
                    :rules="[(v) => !!v || 'Le sexe est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-select>
                </v-col>
                <!--Ecole -->
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete v-model="candidat.nom_ecole" :items="champs.nom_ecole" label="Ecole*"
                    :rules="[(v) => !!v || 'L\'école est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-autocomplete>
                </v-col>

                  <!--Année diplomation -->
              <v-col cols="12" sm="12" md="12" v-if="type_candidat == 'ope' && candidat.statut == 'Embauché'">
                <v-text-field v-model="candidat.annee_diplomation" label="Année de diplomation*" type="number" :rules="[(v) => !!v || 'L\'année de diplomation est obligatoire']" required outlined dense rounded background-color="grey lighten-4" ></v-text-field>
              </v-col>

                <!--Expertise -->
                <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'OK' && type_candidat == 'ope'">
                  <v-text-field v-model="candidat.expertise" label="Expertise" outlined dense rounded
                    background-color="grey lighten-4"></v-text-field>
                </v-col>
                <!--Souhait -->
                <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'OK' && type_candidat == 'ope'">
                  <v-text-field v-model="candidat.souhait" label="Souhait*"
                    :rules="[(v) => !!v || 'Le souhait est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-text-field>
                </v-col>
                <!--Pole -->
                <v-col cols="12" sm="12" :md="(type_candidat == 'ope' && candidat.statut == 'Embauché') ? 6 : 12">
                  <v-select v-model="candidat.pole" :items="champs.poles" label="Pôle*"
                    :rules="[(v) => !!v || 'Le pôle est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-select>
                </v-col>
                
                <!--Pole d'affectation -->
                <v-col cols="12" sm="12" md="6" v-if="type_candidat == 'ope' && candidat.statut == 'Embauché'">
                  <v-select v-model="candidat.pole_affectation" :items="champs.poles" label="Pôle d'affectation*"
                    :rules="[(v) => !!v || `Le pôle d'affectation est obligatoire`]" required outlined dense rounded
                    background-color="grey lighten-4"></v-select>
                </v-col>
                      <!--Entité-->
                      <v-col cols="12" sm="12" md="12" v-if="type_candidat == 'fct'">
                  <v-select v-model="candidat.label_entite" :items="champs.label_entite" label="Entité" outlined dense
                    rounded background-color="grey lighten-4"></v-select>
                </v-col>
                <!--Source chasse -->
                <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'OK'">
                  <v-select v-model="candidat.source_chasse" :items="champs.sources_chasse" clearable
                    clear-icon="mdi-close-circle" label="Source Chasse" outlined dense rounded
                    background-color="grey lighten-4"></v-select>
                </v-col>
                <!--Date de desistement -->
                <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'Désistement'">
                  <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                    min-width="auto" rounded="xl" v-model="isdatedesistement">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :value="candidat.date_desistement" label="Date de desistement"
                        prepend-icon="mdi-calendar" readonly clearable clear-icon="mdi-close-circle" v-bind="attrs"
                        v-on="on" outlined dense rounded background-color="grey lighten-4"></v-text-field>
                    </template>
                    <v-date-picker v-model="candidat.date_desistement" :first-day-of-week="1" locale="fr-FR" @input="closedesistement"></v-date-picker>
                  </v-menu>
                </v-col>
                <!--Désistement -->
                <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'Désistement'">
                  <v-select v-model="candidat.desistement" :items="champs.desistement" label="Desistement*"
                    :rules="[(v) => !!v || 'Le désistement est obligatoire']" outlined dense rounded
                    background-color="grey lighten-4" required></v-select>
                </v-col>
                <!--Motif du desistement -->
                <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'Désistement'">
                  <v-select v-model="candidat.motif_desistement" :items="champs.motif_desistement"
                    label="Motif du désistement*" :rules="[
                      (v) => !!v || 'Le motif de désistement est obligatoire',
                    ]" outlined dense rounded background-color="grey lighten-4" required></v-select>
                </v-col>
                <!--Complément d'information desistement -->
                <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'Désistement'">
                  <v-text-field v-model="candidat.info_desistement" label="Complement d'information désistement" outlined
                    dense rounded background-color="grey lighten-4"></v-text-field>
                </v-col>
                <!--Date de signature -->
                <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'Embauché'">
                  <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                    min-width="auto" rounded="xl" v-model="isDateSignature">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :value="dateEnToFr(candidat.date_signature)" label="Date de signature*"
                        prepend-icon="mdi-calendar" readonly clearable clear-icon="mdi-close-circle" v-bind="attrs"
                        v-on="on" :rules="[
                          (v) => !!v || 'Le date de siganture est obligatoire',
                        ]" outlined dense rounded background-color="grey lighten-4" required></v-text-field>
                    </template>
                    <v-date-picker v-model="candidat.date_signature" :first-day-of-week="1" locale="fr-FR" @input="closeSignaturePicker"></v-date-picker>
                  </v-menu>
                </v-col>
                <!--Date d'entrée -->
                <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'Embauché'">
                  <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                    min-width="auto" rounded="xl" v-model="isdateEntree">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :value="dateEnToFr(candidat.date_entree)" label="Date démarrage contrat*"
                        prepend-icon="mdi-calendar-start" readonly clearable clear-icon="mdi-close-circle" v-bind="attrs"
                        v-on="on" :rules="[
                          (v) => !!v || 'La date d\'entrée est obligatoire',
                        ]" outlined dense rounded background-color="grey lighten-4" required></v-text-field>
                    </template>
                    <v-date-picker v-model="candidat.date_entree" :first-day-of-week="1" locale="fr-FR" @input="closeEntreePicker"></v-date-picker>
                  </v-menu>
                </v-col>
                <!--Date dentree mc2i-->
                <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'Embauché' && type_candidat == 'fct'">
                  <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                    min-width="auto" rounded="xl" v-model="isdateEntreemc2i">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :value="dateEnToFr(candidat.date_entree_mc2i)" label="Date d'entrée mc2i"
                        prepend-icon="mdi-calendar-start" readonly clearable clear-icon="mdi-close-circle" v-bind="attrs"
                        v-on="on"  outlined dense rounded background-color="grey lighten-4" required></v-text-field>
                    </template>
                    <v-date-picker v-model="candidat.date_entree_mc2i" :first-day-of-week="1" locale="fr-FR" @input="closeEntreePicker"></v-date-picker>
                  </v-menu>
                </v-col>
                <!--Matricule-->
                
                <v-col cols="6" sm="6" md="6" v-if="candidat.statut == 'Embauché'">
                  <v-text-field v-model="candidat.matricule" label="Matricule*" type="number"></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="6" v-if="candidat.statut == 'Embauché'">
                  <v-btn @click="generateMatriculeOnClick" rounded color="#1b6a9a" class="white--text ml-10">Générer un matricule</v-btn>
                </v-col>
                
                <!--Fonction-->
                <v-col cols="12" sm="12" md="12" v-if="type_candidat === 'ope' && candidat.statut == 'Embauché'">
                  <v-select v-model="candidat.nom_fonction" :items="champs.nom_fonction"
                    label="Fonction"  outlined dense rounded background-color="grey lighten-4"></v-select>
                </v-col>
                <!--Salaire OPE-->
                <v-col cols="12" sm="12" md="6" v-if="candidat.statut === 'Embauché' && type_candidat === 'ope' && (userRoles.includes(1) || userRoles.includes(2) || userRoles.includes(4) || userRoles.includes(6) || userRoles.includes(7) || userRoles.includes(8) || userRoles.includes(9))">
                  <v-text-field v-model="candidat.salaire" label="Salaire*" type="number" min="0" :rules="[(v) => !!v || 'Le salaire est obligatoire']" required outlined dense rounded background-color="grey lighten-4" ></v-text-field>
                </v-col>
                <!-- Salaire FCT -->
                <v-col cols="12" sm="12" md="6" v-if="candidat.statut === 'Embauché' && type_candidat === 'fct' && (userRoles.includes(1) || userRoles.includes(6) || userRoles.includes(7) || userRoles.includes(8) || userRoles.includes(9))">
                  <v-text-field v-model="candidat.salaire" label="Salaire*" type="number" min="0" :rules="[(v) => !!v || 'Le salaire est obligatoire']" required outlined dense rounded background-color="grey lighten-4" ></v-text-field>
                </v-col>
                <!--Salaire variable OPE-->
                <v-col cols="12" sm="12" md="6" v-if="candidat.statut === 'Embauché' && type_candidat === 'ope' && (userRoles.includes(1) || userRoles.includes(2) || userRoles.includes(4) || userRoles.includes(6) || userRoles.includes(7) || userRoles.includes(8) || userRoles.includes(9))">
                  <v-text-field v-model="candidat.salaire_variable" label="Variable" type="number" min="0" outlined dense rounded background-color="grey lighten-4" ></v-text-field>
                </v-col>
                <!-- Salaire variable FCT -->
                <v-col cols="12" sm="12" md="6" v-if="candidat.statut === 'Embauché' && type_candidat === 'fct' && (userRoles.includes(1) || userRoles.includes(6) || userRoles.includes(7) || userRoles.includes(8) || userRoles.includes(9))">
                  <v-text-field v-model="candidat.salaire_variable" label="Variable" type="number" min="0" outlined dense rounded background-color="grey lighten-4" ></v-text-field>
                </v-col>
                <!--Experience-->
                <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'Embauché'">
                  <v-text-field v-model="candidat.experience" label="Année(s) d'expérience(s) du candidat" type="number"
                    :min="0" :step="0.5" ></v-text-field>
                </v-col>
                <!--Profil-->
                <!-- <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'Embauche'">
                  <v-select v-model="candidat.profil" :items="champs.profils" label="Profil"
                    :rules="[(v) => !!v || 'Le profil est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-select>
                </v-col> -->
                <!--Expertises clés-->
                <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'OK' && type_candidat == 'ope'">
                  <label>Expertises clés</label>
                  <v-chip-group v-model="candidat.expertises_cles" column multiple>
                    <v-chip v-for="expertise_cle in champs.expertises_cles" :key="expertise_cle" :value="expertise_cle"
                      filter outlined>
                      {{ expertise_cle }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
                <!--Origine-->
                <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'Embauché'">
                  <v-select v-model="candidat.origine" :items="champs.origine" label="Origine embauche*"
                    :rules="[(v) => !!v || 'L\'origine est obligatoire']" outlined dense rounded
                    background-color="grey lighten-4"></v-select>
                </v-col>
                <!--Précision origine-->
                <v-col cols="12" sm="12" md="12" v-if="candidat.statut == 'Embauché'">
                  <v-text-field v-model="candidat.precision_origine" :items="champs.precision_origine"
                    label="Precision origine" outlined dense rounded background-color="grey lighten-4"></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="6"
                  v-if="(candidat.statut == 'OK' || candidat.statut == 'Embauché' || candidat.statut == 'Désistement') && type_candidat == 'ope'">
                  <v-select v-model="candidat.expertise_sectorielle" :items="champs.expertises_sectorielles"
                    label="Expertise sectorielle"></v-select>
                </v-col>
                <v-col cols="6" sm="6" md="6"
                  v-if="(candidat.statut == 'OK' || candidat.statut == 'Embauché' || candidat.statut == 'Désistement') && type_candidat == 'ope'">
                  <v-select v-model="candidat.expertise_fct_tech" :items="champs.expertises_fct_tech"
                    label="Expertise fonctionnelle/technique"></v-select>
                </v-col>
                <!--Commentaire -->
                <v-col cols="12" sm="12" md="12">
                  <v-textarea auto-grow v-model="candidat.commentaire" label="Commentaire" outlined dense rounded
                    background-color="grey lighten-4"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="blue" rounded text @click="updateCandidat()" :disabled="!valid">Valider</v-btn>
          <v-btn color="red" rounded text @click="closeDialog()">Annuler</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" color="red" :timeout="5000">
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
  
<script>
import { updateCandidats } from '../../api/candidats';
import { dateEnToFr, dateInText } from '../../commonFunction/date';
import { generateMatricule } from '../../api/matricules'

export default {
  data: () => ({
    dialog: false,
    idCandidat: null,
    type_candidat: "",
    valid: false,
    loading: false,
    isDatePickerOpen: false,
    isPrequaTelOpen: false,
    isDateSignature:false,
    isdateEntree:false,
    isdateEntreemc2i:false,
    isdatedesistement:false,
    snackbar: false,
    snackbarMsg: '',
    candidat: {
      expertises_cles: []
    }
  }),
  methods: {
    openDialog(idCandidat, typeCandidat, candidat) {
      // Récupération de l'id, du type de candidat
      this.idCandidat = idCandidat;
      this.type_candidat = typeCandidat;
      // Copie de l'objet candidat
      this.candidat = { ...candidat };
      if (this.candidat.pole_affectation == null && this.candidat.pole != null) {
        this.candidat.pole_affectation = this.candidat.pole
      }
      //console.log('Disponibilite:', this.candidat.disponibilite);

      // Ouverture du dialogue  
      //console.log(this.candidat.expertises_cles)
      // Séparation des expertises clés pour affichage
      if (this.candidat.expertises_cles) {
        this.candidat.expertises_cles = this.candidat.expertises_cles.split('_') 
      }
      //console.log(this.candidat.expertises_cles)
      this.dialog = true;
    },
    closeDatePicker() {
      this.isDatePickerOpen = false; // Ferme uniquement le menu de calendrier
    },
    closePrequaTelPicker() {
      this.isPrequaTelOpen = false;
    },
    closeSignaturePicker() {
      this.isDateSignature = false;
    },
    closeEntreePicker() {
      this.isdateEntree = false;
      this.isdateEntreemc2i = false
    },
    closedesistement() {
      this.isdatedesistement = false;
    },
    
    async updateCandidat() {
      this.loading = true;
      // On met la date de disponibilité au bon format YYYY-MM-DD pour l'API
      //this.candidat.disponibilite += "-01"
      // On modifie le candidat via l'API 
      this.candidat.matricule = (Number(this.candidat.matricule) || null)
      this.candidat.salaire_variable = (Number(this.candidat.salaire_variable) || null)

      try {
        await updateCandidats(this.idCandidat, this.type_candidat, this.candidat)
        this.loading = false;
        this.closeDialog()
        // Actualisation du tableau des candidats
        this.$emit('candidatUpdated')
      } catch (error) {
        this.loading = false;
        if (error.response.status === 401) {
          this.snackbarMsg = error.response.data
          this.snackbar = true;
        }
      }
    },

    async generateMatriculeOnClick() {
    try {
      // Call the generateMatricule function and pass the access token as an argument
      const matricule = await generateMatricule();

      // Update the candidat object with the generated matricule
      this.candidat.matricule = matricule;

      // Display the generated matricule in a notification or alert
      // You can use a notification library like Vuetify Snackbar for this
      // Example using Vuetify Snackbar:
      this.$store.dispatch('showSnackbar', {
        text: `Matricule généré : ${matricule}`,
        color: 'success',
      });
    } catch (error) {
      console.error('Error generating matricule:', error);

      // Handle the error or display an error message
      // Example using Vuetify Snackbar for error:
      this.$store.dispatch('showSnackbar', {
        text: 'Une erreur s\'est produite lors de la génération du matricule.',
        color: 'error',
      });
    }
  },

    //Fermeture du dialogue
    closeDialog() {
      this.dialog = false
    },
    dateEnToFr, dateInText
  },
  computed: {
    // Récupération des champs nécessaires au remplisssage du formulaire depuis le store
    champs() {
      return this.$store.getters.champs
    },
    // Récupération du rôle de l'utilisateur depuis le store
    userRoles() {
      return this.$store.getters.currentRoles;
    }
  },
  watch: {
    'candidat.expertises_cles'() {
      //console.log(this.candidat.expertises_cles)
    }
  }
};
</script>