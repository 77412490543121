import axiosClient from "./customAxios";


// Obtenir la liste des candidats avec filtres
export const getCandidats = async (filters) => {

	for (let key in filters) {
		if (Array.isArray(filters[key]) && filters[key].length > 0) {
		  filters[key] = filters[key].join(",");
		}
	}
	const config = {
		params: filters
	}
	try {
		const response = await axiosClient.get(process.env.VUE_APP_ROOT_API + `candidats`, config);
		return response.data;
	} catch (error) {
    //   alert("Erreur lors de la récupération des candidats. Veuillez contacter la DNI")
			throw error;
	}
}


// Ajouter un nouveau candidat
export const addCandidats = async ( type, candidat) => {
	
	const config = {
		params: { type: type }
	}
	const data = JSON.stringify(candidat);
	console.log("CREATE CANDIDAT", {type, candidat: JSON.parse(data)})
	try {
		const response = await axiosClient.post(process.env.VUE_APP_ROOT_API + `candidats`, data, config);
		return response;
	} catch (error) {
			throw error;
	}
}


// Supprimer un candidat existant par son ID
export const deleteCandidats = async ( id, type) => {	
	const config = {
    params: { type: type }
	}
	try {
		//console.log("Candidat supprimé")
		const response = await axiosClient.delete(process.env.VUE_APP_ROOT_API + `candidats/${id}`, config);
		return response;
	} catch (error) {
		throw error;
	}
}


// Modifier un candidat existant par son ID 
export const updateCandidats = async ( id, type, candidat) => {
	
	const config = {
		params: { type: type }
	}
	const data = JSON.stringify({...candidat, disponibilite: new Date(candidat.disponibilite)});
	try {
		console.log("UPDATE CANDIDAT", {type, candidat: JSON.parse(data)})
		const response = await axiosClient.post(process.env.VUE_APP_ROOT_API + `candidats`, data, config);
		return response;
	} catch (error) {
			throw error;
	}
}
