// Couleur des lignes du tableau en fonction du statut et des RDVs
export const rowColor = (item) => {
  if (item.statut == "KO" || item.statut == "Désistement") 
    return "red lighten-4";
  else if (item.statut == "Embauché") 
    return "green lighten-4";
  else if (item.offre != undefined && item.offre != "") 
    return "cyan lighten-4";
  else if (item.rdv3 != undefined && item.rdv3 != "") 
    return "orange lighten-4";
  else if (item.rdv2 != undefined && item.rdv2 != "") 
    return "yellow lighten-4";
  else return "grey lighten-4";
}

// Couleur des chip en fonction du statut et des RDVs
export const chipColor = (item) => {
  if (item.statut == "KO" || item.statut == "Désistement")
    return "red lighten-2";
  else if (item.statut == "Embauché")
    return "green lighten-2";
  else if (item.offre != undefined && item.offre != "")
    return "cyan lighten-2";
  else if (item.rdv3 != undefined && item.rdv3 != "")
    return "orange lighten-2";
  else if (item.rdv2 != undefined && item.rdv2 != "")
    return "yellow lighten-2";
  else return "grey lighten-2";
}

export const chipRdvColor = (rdv) => {
  if (rdv.rdvType == "RDV1" )
    return "grey lighten-2";
  else if (rdv.rdvType == "RDV2")
    return "yellow lighten-2 ";
  else if (rdv.rdvType == "RDV3")
    return "orange lighten-2";
  else if (rdv.rdvType == "RDV4")
    return "grey lighten-2";
  else if (rdv.rdvType == "Offre")
    return "cyan lighten-2";
  else return "grey lighten-2";
}