import axiosClient from "./customAxios";

//Ajouter/modifier un candidat en BDD via API
export async function generateMatricule() {

  return axiosClient.get(process.env.VUE_APP_ROOT_API + `matricules/generate`)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      //On  retourne de l'erreur
      //console.log(error);
    });
}
